@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Clash Display Variable";
  src: url("../public/fonts/ClashDisplay-Semibold.woff2") format("woff2"),
    url("../public/fonts/ClashDisplay-Semibold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display Variable";
  src: url("../public/fonts/ClashDisplay-Semibold.woff2") format("woff2"),
    url("../public/fonts/ClashDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: "Clash Display Variable", sans-serif;
}
