@keyframes colorCycle {
  0% {
    border-color: #ff821d;
  }
  25% {
    border-color: #2f3192;
  }
  50% {
    border-color: #25324b;
  }
  75% {
    border-color: #2c2762;
  }
  100% {
    border-color: #ff821d;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-circle {
  position: absolute;
  width: 150px;
  height: 150px;
  border: 10px solid;
  border-radius: 50%;
  animation: colorCycle 3s infinite, rotate 2s linear infinite;
}

.loader-image {
  width: 80px;
  height: 80px;
  background-color: white;
}
